import React from 'react'
import Contact from '../components/Contact'

const Enquiry = () => {
  return (
    <div>
        <Contact/>
    </div>
  )
}

export default Enquiry